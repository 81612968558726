<template>
  <div class="docs">
    <nav-menu></nav-menu>
    <div class="content">
      <div class="services section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-6">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section-heading">
                    <h2>我们提供不同的功能</h2>
                    <div class="line-dec"></div>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-sm-6"
                  v-for="(item, index) in docLists"
                  :key="index"
                  @click="handelClick(item)">
                  <div class="service-item">
                    <div class="icon">
                      <img
                        v-if="index === 0"
                        src="@/assets/images/services-01.jpg"
                        class="templatemo-feature" />
                      <img
                        v-else-if="index === 1"
                        src="@/assets/images/services-04.jpg"
                        class="templatemo-feature" />
                    </div>
                    <h4>{{ item.name }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';
import { getDocsList } from '@/api/docs';

export default {
  name: 'Docs',
  data() {
    return {
      docLists: [],
    };
  },
  components: {
    SiteFooter,
    navMenu,
  },
  mounted() {
    getDocsList().then((res) => {
      this.docLists = res.data;
    });
  },
  methods: {
    handelClick(item) {
      window.open(item.url);
      // this.$router.push({
      //   path: '/docinfo',
      //   query: { id: item.id, name: item.name},
      // });
    },
  },
};
</script>

<style lang="scss" scoped></style>
